
import { Form, Input, Button, InputNumber, Space, Popconfirm, Row, Col } from "antd";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import { useLocation, useNavigate } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";


const CategoryCommentEdit = () => {
    const navigate = useNavigate();
    let { state }: { state: any } = useLocation();
    const onFinish = (values: any) => {

        console.log('save')
        const request =
        {
            Id: state?.id,
            order: values.order,
            experienceWith: values.experienceWith,
            categoryId: values?.categoryId,
            title: values.title,
            placeholder: values.placeholder,
            comments: values.comments
        };

        const promise = state?.id && state?.title
            ? api.put(ApiUrl.updateCategory(), request)//Update 
            : api.post(ApiUrl.addCategory(), request) //Create

        promise
            .then(() => {
                alert(`${state?.id ? 'Update Category' : 'Add Category'} Success`)
                navigate(`/categoryComment/list`)
            })
            .catch(() => alert(`${state?.id ? 'Update Category' : 'Add Category'} Fail`));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={state}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item name="order" label="Order" rules={[{ required: true, message: 'Missing title' }]} >
                <InputNumber />
            </Form.Item>
            <Form.Item name="experienceWith" label="experienceWith" rules={[{ required: true, message: 'Missing title' }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item name="title" label="category title" rules={[{ required: true, message: 'Missing title' }]}>
                <Input />
            </Form.Item>
            <Form.Item name="placeholder" label="Question Text" rules={[{ required: true, message: 'Missing title' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="comments" rules={[{ required: true, message: 'Missing Item' }]}>
                <Form.List name="comments" >
                    {(fields, { add, remove }) => (
                        <div style={{ border: 1, borderColor: '#d9d9d9', borderStyle: "solid", padding: 20 }}>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row align="middle" key={key}>
                                    <Col span={20}>
                                        <Form.Item
                                            style={{ marginBottom: 8, marginRight: 4 }}
                                            labelCol={{ offset: 4 }}
                                            {...restField}
                                            name={[name, 'title']}
                                            rules={[{ required: true, message: 'Missing Item' }]}
                                        >
                                            <Input value={name} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Popconfirm
                                            title="Are you sure to delete?"
                                            onConfirm={() => {
                                                remove(name)
                                                api.delete(ApiUrl.deleteComment(`${state.comments[key].id}`))
                                                    .then(() => {
                                                        console.log(state.comments[key].id)
                                                        alert(`Delete Comment Success`)
                                                    })
                                                    .catch(() => alert(`Delete Comment Fail`));

                                            }}
                                            style={{ marginBottom: 8 }}
                                            okText="Yes"
                                            cancelText="No"
                                            icon={<MinusCircleOutlined style={{ color: 'red' }} />}
                                        >
                                            <a href="#">Delete</a>
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item labelCol={{ offset: 4 }} wrapperCol={{ span: 20 }}>
                                <Button type="dashed" onClick={() => {
                                    add()

                                }} block icon={<PlusOutlined />} >
                                    Add Item
                                </Button>
                            </Form.Item>
                        </div>
                    )}
                </Form.List>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4 }}>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </Form.Item>
        </Form >

    )
}

export default CategoryCommentEdit;