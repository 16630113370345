import { createContext, useReducer } from 'react';
import lscache from 'lscache';
import AppState, { defaultAppState } from '@src/declarations/context/app-state';
import AppAction from '@src/declarations/context/app-action';
import appReducer from './app-reducer';

export class Context {
     static state: AppState;
     static dispatch: React.Dispatch<AppAction>;
}

interface AppContextProviderProps {
     children: React.ReactNode
}

let initialState: AppState = defaultAppState;
const auth = lscache.get('auth');
if (auth) {
     initialState = Object.assign({}, initialState, auth);
}

const AppContext = createContext<{ state: AppState; dispatch: React.Dispatch<AppAction> } | undefined>(undefined);

const AppContextProvider = ({ children }: AppContextProviderProps) => {
     [Context.state, Context.dispatch] = useReducer(appReducer, initialState);

     return (
          <AppContext.Provider value={{ state: Context.state, dispatch: Context.dispatch }}>
               {children}
          </AppContext.Provider>
     )
}

export default AppContext;
export { AppContextProvider };