import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import MainMenu from './main-menu';

const { Sider } = Layout;

const MainSidbar = styled(Sider)`
     width: 200px;
`;

const MainSidebar = () => {
     return (
          <MainSidbar
               breakpoint={"lg"}
               theme="light"
               collapsedWidth={0}
               trigger={null}
          >
               <MainMenu />
          </MainSidbar>
     );
}

export default MainSidebar;