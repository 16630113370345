import { useEffect, useState } from "react";
import { Input, Popconfirm, Space } from "antd";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import { Link } from "react-router-dom";
import DataTable from "@src/components/data-table";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import useQuery from "@src/hooks/use-query";
import VenueOwner from "@src/declarations/venue-owner";


const GetAllUserVenues = () => {
    let { state }: { state: any } = useLocation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<VenueOwner[]>([]);
    const query = useQuery();
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const owner_id = query.get('ownerId');
        if (!owner_id) {
             return;
        }
        loadData(owner_id);
    }, []);

    const loadData = (ownerId: String) => {
        setLoading(true);
       
        api.get(ApiUrl.venueOwner() + `?ownerId=${ownerId}`)
            .then(setData)
            .finally(() => setLoading(false));
    };

    // const approve = (record: VenueOwner) => {
    //     api.patch(ApiUrl.venueOwnerApprove(state.id))
    //         .then(() => alert('Approve Success'))
    //         .catch(() => alert('Approve Fail'))
    // }

    // const reject = (record: VenueOwner) => {
    //     api.patch(ApiUrl.venueOwnerReject(state.id))
    //         .then(() => alert('Reject Success'))
    //         .catch(() => alert('Reject Fail'))
    // }
    // const remove = (record: VenueOwner) => {
    //     api.delete(ApiUrl.venueOwnerDelete(state.id))
    //         .then(() => alert('Remove Success'))
    //         .catch(() => alert('Remove Fail'))
    // }

    return (
        <>
            <DataTable
                columns={[
                    // {
                    //     title: 'Owner FullName',
                    //     dataIndex: 'ownerFullName',
                    //     key: 'ownerFullName',
                    //     sorter: true,
                    //     width: 150,
                    // },
                    {
                        title: 'Venue Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: true
                    },
                    {
                        title: 'Business Legal Name',
                        dataIndex: 'businessLegalName',
                        key: 'businessLegalName',
                        sorter: false,
                    },
                    // {
                    //     title: 'Logo',
                    //     dataIndex: 'logo',
                    //     key: 'logo',
                    //     sorter: false,
                    //     render: (logo: string) => <Image src={logo} max-width={50} />
                    // },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        sorter: false,
                    },
                    // {
                    //     title: 'Phone',
                    //     dataIndex: 'phone',
                    //     key: 'phone',
                    //     sorter: false,
                    // },
                    // {
                    //     title: 'Mobile',
                    //     dataIndex: 'mobile',
                    //     key: 'mobile',
                    //     sorter: false,
                    // },
                    // {
                    //     title: 'Website',
                    //     dataIndex: 'website',
                    //     key: 'website',
                    //     sorter: false,
                    // },
                    // {
                    //     title: 'Description',
                    //     dataIndex: 'description',
                    //     key: 'description',
                    //     sorter: false,
                    // },
                    // {
                    //     title: 'Cover Photo',
                    //     dataIndex: 'coverPhoto',
                    //     key: 'coverPhoto',
                    //     sorter: false,
                    //     render: (coverPhoto: string) => <Image src={coverPhoto} max-width={50}/>

                    // },
                    // {
                    //     title: 'Images',
                    //     dataIndex: 'images',
                    //     key: 'images',
                    //     sorter: false,
                    //     width: 100,
                    //     render: (images) => images.map((image: string, index: number) => <Image src={image} height={50} key={index} />)
                    // },
                    {
                        title: 'State',
                        dataIndex: 'stateString',
                        key: 'stateString',
                        sorter: false,
                        width: 100,
                    },
                    {
                        title: 'Submission Date',
                        dataIndex: 'creationDate',
                        key: 'creationDate',
                        sorter: true,
                        render: (creationDate) => moment(creationDate).format('l, h:m A')
                    },
                    {
                        title: 'Rejection Date',
                        dataIndex: 'rejectionDate',
                        key: 'rejectionDate',
                        sorter: true,    
                    },
                    {
                        title: 'Approval Date',
                        dataIndex: 'approvalDate',
                        key: 'approvalDate',
                        sorter: true,
                        // render: (approvalDate) => moment(approvalDate).format('l, h:m A')
                    },
                    //     {
                    //         title: 'Suitable For Wheelchairs',
                    //         dataIndex: 'suitableForWheelchairs',
                    //         key: 'suitableForWheelchairs',
                    //         sorter: true,
                    //         render:(verify:boolean) => <Checkbox checked = {verify} />
                    //    },
                    //    {
                    //         title: 'Suitable For Blinds',
                    //         dataIndex: 'suitableForBlinds',
                    //         key: 'suitableForBlinds',
                    //         sorter: true,
                    //         render:(verify:boolean) => <Checkbox checked = {verify} />
                    //     },
                    //     {
                    //         title: 'Suitable For Deafs',
                    //         dataIndex: 'suitableForDeafs',
                    //         key: 'suitableForDeafs',
                    //         sorter: true,
                    //         render:(verify:boolean) => <Checkbox checked = {verify} />
                    //     },
                    {
                        title: 'Action',
                        key: 'action',
                        render: (_, record) => (
                            <Space size="middle">
                                <Link to="/venueOwner/edit" state={record}>Details</Link>
                                {/* <Popconfirm
                                        title="Are you sure to delete?"
                                        onConfirm={() => remove(record as VenueOwner)}
                                        okText="Yes"
                                        cancelText="No"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                   >
                                        <a href="#">Delete</a>
                                   </Popconfirm>
                                <Popconfirm
                                    title="Are you sure to approve?"
                                    onConfirm={() => approve(record as VenueOwner)}
                                    okText="Yes"
                                    cancelText="No"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                >
                                    <a href="#">Approve</a>
                                </Popconfirm>
                                <Popconfirm
                                    title="Are you sure to reject?"
                                    onConfirm={() => reject(record as VenueOwner)}
                                    okText="Yes"
                                    cancelText="No"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                >
                                    <a href="#">Reject</a>
                                </Popconfirm> */}
                            </Space>
                        ),
                    },

                ]}
                dataSource={data}
                loading={loading}
            />
        </>
    );
}

export default GetAllUserVenues;

