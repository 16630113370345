import { Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

const MainContent = styled(Content)`
     padding: 24px;
     margin: 0;
     min-height: 280px;
     background-color: white;
`;

export default MainContent;