import api from "@src/api";
import { ApiUrl } from "@src/api/api-url";
import DataTable from "@src/components/data-table";
import ContactUsMessages from "@src/declarations/contactus";
import moment from "moment";
import { useEffect, useState } from "react";


const ContactUsListMessages = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ContactUsMessages[]>([]);

    useEffect(() => {
        document.title = 'Inclusion Atlas | Contact Us';
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        api.get(ApiUrl.contactUs())
            .then(setData)
            .finally(() => setLoading(false));
    };


    return (

        <DataTable
            columns={[
                {
                    title: 'First Name',
                    dataIndex: 'name',
                    key: 'name',
                    sorter: true,
                },
                {
                    title: 'Last Name',
                    dataIndex: 'sureName',
                    key: 'sureName',
                    sorter: true,
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    sorter: true,
                },
                {
                    title: 'Message',
                    dataIndex: 'message',
                    key: 'message',
                    sorter: true,
                },
                {
                    title: 'Create Date',
                    dataIndex: 'createDate',
                    key: 'createDate',
                    sorter: true,
                    render: (createDate) => moment(createDate).format('l, h:m A')
                },

                // {
                //     title: 'Action',
                //     key: 'action',
                //     render: (_, record) => (
                //         <Space size="middle">
                //             <Link to="/notification/edit" state={record}>Edit</Link>
                //         </Space>
                //     ),
                // },
            ]}
            dataSource={data}
            loading={loading}
        />

    )


}
export default ContactUsListMessages