import { useEffect, useState } from "react";
import { Popconfirm, Space, Button } from "antd";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import { Link } from "react-router-dom";
import DataTable from "@src/components/data-table";
import { QuestionCircleOutlined } from "@ant-design/icons";
import translation from "src/declarations/translation/index";

const TranslationList = () => {
     const [loading, setLoading] = useState(false);
     const [data, setData] = useState<translation[]>([]);

     useEffect(() => {
          document.title = 'Inclusion Atlas | Translation';
          loadData();
     }, []);

     const loadData = () => {
          setLoading(true);
          api.get(ApiUrl.translations())
               .then(setData)
               .finally(() => setLoading(false));
     };

     const remove = (record: translation) => {
          setLoading(true);
          api.delete(ApiUrl.translationDelete(record.id))
               .then(loadData)
               .catch(() => setLoading(false));
     }

     return (
          <>
               <Link to="/translation/edit" state={{jsonContent: {}}}>
                    <Button type='primary'>
                         New Translation
                    </Button></Link>
               <DataTable
                    columns={[
                         {
                              title: 'Locale',
                              dataIndex: 'locale',
                              key: 'locale',
                              sorter: true,
                         },
                         {
                              title: 'CreationDate',
                              dataIndex: 'creationDate',
                              key: 'creationDate',
                              sorter: false,
                         },
                         {
                              title: 'Action',
                              key: 'action',
                              render: (_, record) => (
                                   <Space size="middle">
                                        <Link to="/translation/edit" state={record}>Edit</Link>
                                        <Popconfirm
                                             title="Are you sure to delete ?"
                                             onConfirm={() => remove(record as translation)}
                                             okText="Yes"
                                             cancelText="No"
                                             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        >
                                             <a href="#">Delete</a>
                                        </Popconfirm>
                                   </Space>
                              ),
                         },
                    ]}
                    dataSource={data}
                    loading={loading}
               /></>
     );
}

export default TranslationList;