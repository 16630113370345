import { Form, Input, Button, Image } from 'antd';
import api from '@src/api';
import { ApiUrl } from '@src/api/api-url';
import { useLocation, useNavigate } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';


const VenueOwnerEdit = () => {

    const navigate = useNavigate();
    let { state }: { state: any } = useLocation();

    const { TextArea } = Input;

    const approve = () => {
        api.patch(ApiUrl.venueOwnerApprove(state.id))
            .then(() => {
                alert('Approve Success')
                navigate(`/venueOwner/list`)
            })
            .catch(() => alert('Approve Fail'))
    }

    const reject = () => {
        api.patch(ApiUrl.venueOwnerReject(state.id))
            .then(() => {
                alert('Reject Success')
                navigate(`/venueOwner/list`)
            })
            .catch(() => alert('Reject Fail'))
    }

    const remove = () => {
        api.delete(ApiUrl.venueOwnerDelete(state.id))
            .then(() => {
                alert('Remove Success')
                navigate(`/venueOwner/list`)
            })
            .catch(() => alert('Remove Fail'))
    }


    return (
        <Form
            layout="horizontal"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 14 }}
            initialValues={state}
        >
            <Form.Item name='placeId' label='Google Place ID' >
                <Input />
            </Form.Item>
            <Form.Item name="name" label="Name">
                <Input />
            </Form.Item>
            <Form.Item name="ownerFullName" label="Owner FullName">
                <Input />
            </Form.Item>
            <Form.Item name="businessLegalName" label="Business Legal Name">
                <Input />
            </Form.Item>
            <Form.Item name="email" label="Email">
                <Input />
            </Form.Item>
            <Form.Item name="phone" label="Phone">
                <Input />
            </Form.Item>
            <Form.Item name="mobile" label="Mobile">
                <Input />
            </Form.Item>
            <Form.Item name="website" label="Website">
                <Input />
            </Form.Item>
            <Form.Item name="stateString" label="Status">
                <Input />
            </Form.Item>
            {/* <Form.Item name="description" label="Description">
                <TextArea />
            </Form.Item> */}
            <Form.Item name="logo" label="Logo">
                <Image style={{ marginLeft: 8 }} width={50} src={state.logo} />
            </Form.Item>
            <Form.Item name="coverPhoto" label="Cover Photo">
                <Image style={{ marginLeft: 8 }} width={100} src={state.coverPhoto} />
            </Form.Item>
            <Form.List name="images">
                {(fields) => (
                    <Form.Item label="Venue Images">
                        {fields.map((field, index) => (
                            // <div key={index} style={{display:'flex',flexDirection:'row',padding:2}}>
                                <Image style={{ marginLeft: 8, padding:8 }} width={100} height={100} src={state.images[field.key]} key={index}/>
                            // </div>
                        ))}
                    </Form.Item>
                )}
            </Form.List>
            <Form.List name="documents">
                {(fields) => (
                    <Form.Item label="Venue Documents">
                        {fields.map((field, index) => (
                            <div key={index}>
                                <a style={{ marginLeft: 8 }} href={`${state.documents[field.key].url}`} target={'_blank'} download >{`${state.documents[field.key].description}`}</a>
                            </div>
                        ))}
                    </Form.Item>
                )}
            </Form.List>
            <Form.Item wrapperCol={{ offset: 4 }}>

                <Button type="primary" htmlType="button" style={{ marginRight: 8 }}
                    onClick={() => { approve(); }}>
                    Approve
                </Button>
                <Button type="primary" danger htmlType="button" style={{ marginRight: 8 }}
                    onClick={() => { reject(); }}>
                    Reject
                </Button>
                <Button type="primary" danger htmlType="button"
                    onClick={() => { remove(); }}>
                    Remove
                </Button>
            </Form.Item>
        </Form >
    )
}

export default VenueOwnerEdit;