import { useEffect, useState } from "react";
import { Image, Popconfirm, Space, Table } from "antd";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import { Link } from "react-router-dom";
import Category from "@src/declarations/category";
import { QuestionCircleOutlined } from "@ant-design/icons";

const CategoryList = () => {
     const [loading, setLoading] = useState(false);
     const [data, setData] = useState<Category[]>([]);
     useEffect(() => {
          document.title = 'Inclusion Atlas | Categories';
          loadData();
     }, []);

     const loadData = () => {
          setLoading(true);
          api.get(ApiUrl.categories())
               .then(setData)
               .finally(() => setLoading(false));
     };

     const remove = (record: Category) => {
          setLoading(true);
          api.delete(ApiUrl.categoryDelete(record.id))
               .then(loadData)
               .catch(() => setLoading(false));
     }

     return (
          <Table
               columns={[
                    {
                         title: 'Name',
                         dataIndex: 'name',
                         key: 'name',
                         sorter: true,
                    },
                    {
                         title: 'Order',
                         dataIndex: 'order',
                         key: 'order',
                         sorter: true,
                    },
                    {
                         title: 'Google Category',
                         dataIndex: 'googleCategory',
                         key: 'googleCategory',
                         sorter: true,
                    },
                    // {
                    //      title: 'Sub Categories',
                    //      dataIndex: 'subCategories',
                    //      key: 'subCategories',
                    //      render:(subCategories) => subCategories.map((item: { name: string; id:string; }) => <span key={item.id}> {item.name}, </span>  )
                    // },                  
                    {
                         title: 'Image',
                         dataIndex: 'image',
                         key: 'image',
                         render: (image: string) => <Image height={25} src={image} />
                    },
                    {
                         title: 'Action',
                         key: 'action',
                         render: (_, record) => (
                              <Space size="middle">
                                   <Link to="/category/edit" state={record}>Edit</Link>
                                   <Popconfirm
                                        title="Are you sure to delete ?"
                                        onConfirm={() => remove(record as Category)}
                                        okText="Yes"
                                        cancelText="No"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                   >
                                        <a href="#">Delete</a>
                                   </Popconfirm>
                              </Space>
                         ),
                    },
               ]}
               dataSource={data}
               loading={loading}
          />
     );
}

export default CategoryList;