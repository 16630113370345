import { useEffect, useState } from "react";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import DataTable from "@src/components/data-table";
import { Popconfirm, Space, Checkbox, Input, Image } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import User from "@src/declarations/user";
import moment from "moment";


const UserList = () => {
     const [loading, setLoading] = useState(false);
     const [data, setData] = useState<User[]>([]);
     const [searchText, setSearchText] = useState('');

     useEffect(() => {
          loadData();
     }, []);

     const loadData = () => {
          document.title = 'Inclusion Atlas | User List';
          setLoading(true);
          api.get(ApiUrl.users())
               .then(setData)
               .finally(() => setLoading(false));
     };

     const remove = (record: User) => {
          setLoading(true);
          api.delete(ApiUrl.userDelete(record.id))
               .then(loadData)
               .catch(() => setLoading(false));
     }

     return (
          <>
               <Input.Search
                    placeholder="search records..."
                    style={{ marginBottom: 8 }}
                    onSearch={(value) => { setSearchText(value) }}
                    onChange={(e) => { setSearchText(e.target.value) }}
               />
               <DataTable
                    columns={[
                         {
                              title: 'Username',
                              dataIndex: 'username',
                              key: 'username',
                              sorter: true,
                              filteredValue: [searchText],
                              onFilter: (value, record) => {
                                   return (record as User).username.toLowerCase().includes((value as string).toLowerCase()) ||
                                        (record as User).fullName.toLowerCase().includes((value as string).toLowerCase())
                              }
                         },
                         {
                              title: 'Full Name',
                              dataIndex: 'fullName',
                              key: 'fullName',
                              sorter: true,
                         },
                         // {
                         //      title: 'Avatar',
                         //      dataIndex: 'avatarURL',
                         //      key: 'avatarURL',
                         //      render: (avatarURL: string) => <Image src={avatarURL} width={50} height={50}/>
                         // },
                         {
                              title: 'Verified',
                              dataIndex: 'verified',
                              key: 'verified',
                              sorter: true,
                              render: (verify: boolean) => <Checkbox checked={verify} />
                         },
                         {
                              title: 'Creation Date',
                              dataIndex: 'creationDate',
                              key: 'creationDate',
                              sorter: true,
                              render: (creationDate) => moment(creationDate).format('l, h:m A')

                         },
                         {
                              title: 'Action',
                              key: 'action',
                              render: (_, record) => (
                                   <Space size="middle">
                                        {/* <Link to="/user/edit" state={record}>Edit</Link> */}
                                        <Popconfirm
                                             title="Are you sure to delete ?"
                                             onConfirm={() => remove(record as User)}
                                             okText="Yes"
                                             cancelText="No"
                                             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        >
                                             <a href="#">Delete</a>
                                        </Popconfirm>
                                   </Space>
                              ),
                         },
                    ]}
                    dataSource={data}
                    loading={loading}
               />
          </>
     )
}
export default UserList