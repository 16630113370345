
import { Form, Input, Button, InputNumber, Row, Col, Popconfirm } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import api from '@src/api';
import { ApiUrl } from '@src/api/api-url';
import { useLocation, useNavigate } from 'react-router-dom';
import UploadFile from '@src/components/upload-file';

const CategoryEdit = () => {
     const navigate = useNavigate();
     const { state }: { state: any } = useLocation();

     const onFinish = (values: any) => {
          console.log('save')
          // debugger
          const formData = new FormData();
          formData.append('name', values.name);
          formData.append('googleCategory', values.googleCategory);
          formData.append('order', values.order.toString());
          if (values.image) {
               formData.append('image', values.image);
          }
          formData.append('subCategories', JSON.stringify(values.subCategories));
          const promise = state?.id
               ? api.patch(ApiUrl.categoryModify(state.id), formData)//Modify 
               : api.post(ApiUrl.categoryCreate(), formData) //Create

          promise
               .then(() => {
                    alert(`${state?.id ? 'Modify category' : 'Create category'} Success`)
                    navigate(`/category/list`)
               })
               .catch(() => alert(`${state?.id ? 'Modify category' : 'Create category'} Fail`));
     };

     const onFinishFailed = (errorInfo: any) => {
          console.log('Failed:', errorInfo);
     };

     return (
          <Form
               layout="horizontal"
               labelCol={{ span: 4 }}
               wrapperCol={{ span: 14 }}
               initialValues={state}
               onFinish={onFinish}
               onFinishFailed={onFinishFailed}
          >
               <Form.Item name="name" label="Name">
                    <Input />
               </Form.Item>
               <Form.Item name="googleCategory" label="Google Category">
                    <Input />
               </Form.Item>
               <Form.Item name="order" label="Order">
                    <InputNumber />
               </Form.Item>
               <Form.Item name="image" label="Image">
                    <UploadFile />
               </Form.Item>

               <Form.Item label="subCategories"
               // rules={[{ required: true }]}
               >
                    <Form.List name="subCategories" >
                         {(fields, { add, remove }) => (
                              <div >
                                   {fields.map(({ key, name, ...restField }) => (
                                        <Row align="middle" key={key} style={{ borderBottom: 1, borderColor: '#d9d9d9', borderStyle: "solid", padding: 20 }}>
                                             <Col span={24}>
                                                  <Form.Item
                                                       style={{ marginBottom: 8, marginRight: 4 }}
                                                       labelCol={{ offset: 1 }}
                                                       {...restField}
                                                       name={[name, `name`]}
                                                       // rules={[{ required: true }]}
                                                       label={'Name'}
                                                  >
                                                       <Input value={name} />
                                                  </Form.Item>
                                                  <Form.Item
                                                       style={{ marginBottom: 8, marginRight: 4 }}
                                                       labelCol={{ offset: 1 }}
                                                       {...restField}
                                                       name={[name, 'googleCategory']}
                                                       label={'Google Category'}
                                                  // rules={[{ required: true }]}
                                                  >
                                                       <Input value={name} />
                                                  </Form.Item>
                                                  <Form.Item
                                                       style={{ marginBottom: 8, marginRight: 4 }}
                                                       labelCol={{ offset: 1 }}
                                                       {...restField}
                                                       name={[name, `order`]}
                                                       // rules={[{ required: true }]}
                                                       label={'Order'}
                                                  >
                                                       <InputNumber value={name} />
                                                  </Form.Item>
                                                  <Form.Item
                                                       style={{ marginBottom: 8, marginRight: 4 }}
                                                       labelCol={{ offset: 1 }}
                                                       {...restField}
                                                       name={[name, `image`]}
                                                       label={"Image"}
                                                  >
                                                       <UploadFile />
                                                  </Form.Item>
                                             </Col>
                                             <Col span={24} style={{ marginBottom: 8, padding: 8 }} >
                                                  <Popconfirm
                                                       title="Are you sure to delete?"
                                                       onConfirm={() => {
                                                            remove(name)
                                                            api.delete(ApiUrl.subCategoryDelete(`${state.id}`, `${state.subCategories[key].id}`))
                                                                 .then(() => {
                                                                      alert(`Delete SubCategory Success`)
                                                                 })
                                                                 .catch(() => alert(`Delete SubCategory Fail`));
                                                       }}
                                                       style={{ marginBottom: 8 }}
                                                       okText="Yes"
                                                       cancelText="No"
                                                       icon={<MinusCircleOutlined style={{ color: 'red' }} />}
                                                  >
                                                       <a style={{ margin: 10, padding: 8, border: 1, borderStyle: 'solid', borderColor: 'red', color: 'red' }} href="#">Delete Item</a>
                                                  </Popconfirm>
                                             </Col>
                                        </Row>
                                   ))}
                                   <Form.Item labelCol={{ offset: 4 }} wrapperCol={{ span: 20 }} style={{ margin: 8 }}>
                                        <Button type="dashed" onClick={() => {
                                             add()
                                        }} block icon={<PlusOutlined />} >
                                             Add Item
                                        </Button>
                                   </Form.Item>
                              </div>
                         )}
                    </Form.List>
               </Form.Item>



               <Form.Item wrapperCol={{ offset: 4 }}>
                    <Button type="primary" htmlType="submit">
                         Save
                    </Button>
               </Form.Item>
          </Form >
     );
}

export default CategoryEdit;