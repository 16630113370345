import { useEffect, useState } from "react";
import { Image, Popconfirm, Space, Table } from "antd";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import { Link } from "react-router-dom";
import DataTable from "@src/components/data-table";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useLocation } from 'react-router-dom';
import Category from "@src/declarations/comment";

const CommentsList = () => {
     const [loading, setLoading] = useState(false);
     const [data, setData] = useState<Category[]>([]);
     let { state }: { state: any } = useLocation();

     useEffect(() => {
          document.title = 'Inclusion Atlas | Reviews';
          loadData();
     }, []);

     const loadData = () => {
          setLoading(true);
          api.get(ApiUrl.getComments())
               .then(setData)
               .finally(() => setLoading(false));
     };

     const remove = (record: Category) => {
          setLoading(true);
          api.delete(ApiUrl.deleteCategory(record.id))
               .then(loadData)
               .catch(() => setLoading(false));
     }

     return (
          <DataTable
               columns={[
                    {
                         title: 'Category Title',
                         dataIndex: 'title',
                         key: 'title',
                         sorter: true,
                         width: 70,
                    },
                    {
                         title: 'Question Text',
                         dataIndex: 'placeholder',
                         key: 'placeholder',
                         sorter: true,
                         width: 70,
                    },
                    {
                         title: 'Order ',
                         dataIndex: 'order',
                         key: 'order',
                         sorter: true,
                         // fixed:'left',
                         width: 50,
                    },
                    // {
                    //      title: 'Experience With ',
                    //      dataIndex: 'experienceWith',
                    //      key: 'experienceWith',
                    //      sorter: true,
                    //      // fixed:'left',
                    //      width: 60,
                    // },
                    // {
                    //      title: 'Display Title',
                    //      dataIndex: 'displayTitle',
                    //      key: 'displayTitle',
                    //      sorter: true,
                    //      width: 100,
                    // },
                    {
                         title: 'Description',
                         dataIndex: 'description',
                         key: 'description',
                         sorter: true,
                         width: 110,
                    },
                    {
                         title: 'Comments',
                         dataIndex: 'comments',
                         key: 'comments',
                         width: 200,
                         render: (comments, index) => comments.map((item: { title: string; }, index: string) => item.title).join(', ')
                    },

                    // {
                    //      title: 'Category Title',
                    //      dataIndex:'comments',
                    //      key:'comments',
                    //      render:(comments) => comments.map((item: { categoryTitle: string; }) => item.categoryTitle).join()                                                                        
                    // },

                    {
                         title: 'Action',
                         key: 'action',
                         width: 100,
                         render: (_, record) => (
                              <Space size="middle">
                                   <Link to="/categoryComment/edit" state={record}>Edit</Link>
                                   <Popconfirm
                                        title="Are you sure to delete?"
                                        onConfirm={() => remove(record as Category)}
                                        okText="Yes"
                                        cancelText="No"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                   >
                                        <a href="#">Delete</a>
                                   </Popconfirm>
                              </Space>
                         ),
                    },
               ]}
               dataSource={data}
               loading={loading}
          />
     );
}

export default CommentsList;

function rec(rec: any): string {
     throw new Error("Function not implemented.");
}
