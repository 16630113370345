import { Button, Form, Input, Select } from "antd";
import { useLocation } from "react-router-dom";
import { ApiUrl } from "@src/api/api-url";
import api from "@src/api";
import { hover } from "@testing-library/user-event/dist/hover";
import { useEffect, useState } from "react";
import { DefaultEditor } from 'react-simple-wysiwyg';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const modules = {
     toolbar: [
          [{ 'font': [] },{'size':[]}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image'],
          ['clean']
     ],
};
const formats = [
     'font','size',
     'bold', 'italic', 'underline', 'strike', 'blockquote',
     'list', 'bullet', 'indent',
     'link', 'image'
];
const NotificationBody = ({ value, onChange }: { value?: string, onChange?: (value: string) => void }) => {
     return (
          <DefaultEditor value={value || ''} onChange={(e: any) => {onChange?.(e.target.value)}} />
     );
}

const EmailTemplateEdit = () => {
     const { state }: { state: any } = useLocation();
     const [value, setValue] = useState('');
     const onFinish = (values: any) => {
          const request =
          {
               Id: state?.id,
               type: Number(values.type),
               subject: values.subject,
               body: values.body.toString()
          };

          const promise = state?.id
               ? api.put(ApiUrl.updateEmailTemplate(), request)//Update 
               : api.post(ApiUrl.addEmailTemplate(), request) //Create

          promise
               .then(() => alert(`${state?.id ? 'Update Email Template' : 'Add Email Template'} Success`))
               .catch(() => alert(`${state?.id ? 'Update Email Template' : 'Add Email Template'} Fail`));
     };

     const onFinishFailed = (errorInfo: any) => {
          console.log('Failed:', errorInfo);
     };

     return (
          <Form
               layout="horizontal"
               labelCol={{ span: 4 }}
               wrapperCol={{ span: 14 }}
               initialValues={state}
               onFinish={onFinish}
               onFinishFailed={onFinishFailed}
          >
               <Form.Item name="type" label="Type">
                    <Input />
               </Form.Item>

               <Form.Item name="subject" label="Subject">
                    <Input />
               </Form.Item>
               <Form.Item name="body" label="Body">
                    {/* <NotificationBody /> */}
                    <ReactQuill
                         theme='snow'
                         formats={formats}
                         modules={modules}
                         value={value || ''}
                         onChange={setValue}
                    />
               </Form.Item>

               <Form.Item wrapperCol={{ offset: 4 }}>
                    <Button type="primary" htmlType="submit">
                         Save
                    </Button>
               </Form.Item>
          </Form >
     );
}

export default EmailTemplateEdit;