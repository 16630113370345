import { QuestionCircleOutlined } from "@ant-design/icons";
import api from "@src/api";
import { ApiUrl } from "@src/api/api-url";
import DataTable from "@src/components/data-table";
import Messages from "@src/declarations/messages";
import { Button, Input, Popconfirm, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";


const MessagesList = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Messages[]>([]);
    const [searchText, setSearchText] = useState('');
    let { state }: { state: any } = useLocation();

    useEffect(() => {
        document.title = 'Inclusion Atlas | Messages List';
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        api.get(ApiUrl.messages())
            .then(setData)
            .finally(() => setLoading(false));
    };


    const remove = (record: Messages) => {
        setLoading(true);
        api.delete(ApiUrl.deleteMessage(record.id))
            .then(loadData)
            .catch(() => setLoading(false))
    }

    // const sendToOwner =() =>{
    //     const request =
    //     {
    //         Id: state?.id,
    //         venueId: state?.venueId,
    //         body: state?.body,
    //     };
    //     console.log('send');
    //     api.post(ApiUrl.addFromAdmin(),request)
    //     .then(()=>{alert('send to owner success')})
    //     .catch(()=>{alert('send fail')})
    // }


    return (
        <>
            <Input.Search
                placeholder="search records..."
                style={{ marginBottom: 8 }}
                onSearch={(value) => { setSearchText(value) }}
                onChange={(e) => { setSearchText(e.target.value) }}
            />
            <DataTable
                columns={[
                    {
                        title: 'Venue Name',
                        dataIndex: 'venueName',
                        key: 'venueName',
                        sorter: true,
                        filteredValue: [searchText],
                        onFilter: (value, record) => {
                            return (record as Messages).venueName.toLowerCase().includes((value as string).toLowerCase()) ||
                                (record as Messages).firstName.toLowerCase().includes((value as string).toLowerCase()) ||
                                (record as Messages).lastName.toLowerCase().includes((value as string).toLowerCase())
                        }
                    },
                    {
                        title: 'First Name',
                        dataIndex: 'firstName',
                        key: 'firstName',
                        sorter: true,
                    },
                    {
                        title: 'Last Name',
                        dataIndex: 'lastName',
                        key: 'lastName',
                        sorter: true,
                    },
                    {
                        title: 'Email Address',
                        dataIndex: 'email',
                        key: 'email',
                        sorter: true,
                    },
                    {
                        title: 'Subject',
                        dataIndex: 'subject',
                        key: 'subject',
                        sorter: true,
                    },
                    {
                        title: 'Body',
                        dataIndex: 'body',
                        key: 'body',
                        sorter: true,
                    },
                    {
                        title: 'Owner Response',
                        dataIndex: 'ownerResponse',
                        key: 'ownerResponse',
                        sorter: true,
                    },
                    {
                        title: 'Owner Response Date',
                        dataIndex: 'ownerResponseDate',
                        key: 'ownerResponseDate',
                        sorter: true,
                    },
                    {
                        title: 'Create Date',
                        dataIndex: 'createDate',
                        key: 'createDate',
                        sorter: true,
                        render: (createDate) => moment(createDate).format('l, h:mm A')
                    },

                    {
                        title: 'Action',
                        key: 'action',
                        width: 200,
                        render: (_, record) => (
                            <Space size="middle">
                                {/* <Button type="primary" ghost onClick={()=> sendToOwner()}>Send to Owner</Button> */}
                                <Popconfirm
                                    title="Are you sure to delete?"
                                    onConfirm={() => remove(record as Messages)}
                                    okText="Yes"
                                    cancelText="No"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                >
                                    <a href="#">Delete</a>
                                </Popconfirm>
                            </Space>
                        ),
                    },
                ]}
                dataSource={data}
                loading={loading}
            />
        </>

    )


}
export default MessagesList