import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { GroupOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { SubMenu } = Menu;

const WrappedMenu = styled(Menu)`
     height: 100%;
     border-right: 0;
     width:250px;
`;

const MainMenu = () => {
     const navogate = useNavigate();

     const onClick = ({ key }: { key: string }) => {
          navogate(key);
     }

     return (
          <WrappedMenu
               mode="inline"
               defaultSelectedKeys={["category/list"]}
               defaultOpenKeys={["categories"]}
               onClick={onClick}
          >
               <SubMenu key="categories" icon={<GroupOutlined />} title="Categories" >
                    <Menu.Item key="category/list">Category List</Menu.Item>
                    <Menu.Item key="category/edit" >New Category</Menu.Item>
               </SubMenu>
               <SubMenu key="capabilities" icon={<GroupOutlined />} title="Capabilities" >
                    <Menu.Item key="capability/list">Capability List</Menu.Item>
                    <Menu.Item key="capability/edit" >New Capability</Menu.Item>
               </SubMenu>
               <SubMenu key="comments" icon={<GroupOutlined />} title="Comment Categories" >
                    <Menu.Item key="categoryComment/list">Category List</Menu.Item>
                    <Menu.Item key="categoryComment/edit" >New Category</Menu.Item>
               </SubMenu>
               <SubMenu key="VenueReviews" icon={<GroupOutlined />} title="Venue Reviews" >
                    <Menu.Item key="VenueReview/list">Venue Reviews List</Menu.Item>
               </SubMenu>
               <SubMenu key="venue-owner" icon={<GroupOutlined />} title="Venue Owners " >
                    <Menu.Item key="venueOwner/list">Venue Owner List</Menu.Item>
               </SubMenu>
               <SubMenu key="users" icon={<GroupOutlined />} title="Users" >
                    <Menu.Item key="user/list">Users List</Menu.Item>
               </SubMenu>
               <SubMenu key="translations" icon={<GroupOutlined />} title="translations" >
                    <Menu.Item key="translation">translation List</Menu.Item>
               </SubMenu>
               <SubMenu key="notifications" icon={<GroupOutlined />} title="Notifications" >
                    <Menu.Item key="notification/list">Email Template List</Menu.Item>
                    <Menu.Item key="notification/edit">New Email Template</Menu.Item>
               </SubMenu>
               <SubMenu key="messages" icon={<GroupOutlined />} title="Message to venue owners" >
                    <Menu.Item key="message/list">Messages List</Menu.Item> 
               </SubMenu>
               <SubMenu key="contactUs" icon={<GroupOutlined />} title="Contact Us" >
                    <Menu.Item key="contactUs/list">Contact Us Messages List</Menu.Item> 
               </SubMenu>
               <SubMenu key="Subscriber" icon={<GroupOutlined />} title="Subscribe" >
                    <Menu.Item key="subscriber/list">Subscriber List</Menu.Item> 
               </SubMenu>
          </WrappedMenu>
     );
}

export default MainMenu;