export default interface AppState {
     userId: string;
     emailAddress: string;
     accessToken: string;
}

export const defaultAppState:AppState = {
     userId: '',
     emailAddress: '',
     accessToken: ''
}
