import Sort, { SortOrder } from "@src/declarations/common/sort";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";

interface DataTableProps {
     columns: ColumnsType<object>,
     dataSource: any[],
     loading: boolean
}

const DataTable = (props: DataTableProps) => {
     const [current, setCurrent] = useState(1);
     const [pageSize, setPageSize] = useState(10);
     const [sort, setSort] = useState<Sort>({
          field: undefined,
          order: undefined
     });

     const getDataTable = () => {
          let { dataSource: data } = props;
          if (sort.field) {
               data.sort((a, b) => {
                    if (a[sort.field!] > b[sort.field!]) {
                         return sort.order == SortOrder.Ascend ? 1 : -1;
                    }
                    else if (a[sort.field!] < b[sort.field!]) {
                         return sort.order == SortOrder.Ascend ? -1 : 1;
                    }
                    else {
                         return 0;
                    }
               });
          }
          const start = (current - 1) * pageSize;
          const end = start + pageSize;
          return data.slice(start, end)
     }

     const handleTableChange = (pagination: any, filters: any, sorter: any) => {
          setSort({
               field: sorter.field,
               order: sorter.order && (sorter.order == "ascend" ? SortOrder.Ascend : SortOrder.Descend)
          })
          setCurrent(pagination.current);
          setPageSize(pagination.pageSize);
     }

     return <Table
          rowKey="id"
          columns={props.columns}
          tableLayout= 'fixed'
          scroll={{
               x: 1000,
               y:600
             }}
          pagination={{
               current,
               pageSize,
               total: props.dataSource.length
          }}
          dataSource={getDataTable()}
          loading={props.loading}
          onChange={handleTableChange}
     />
}

export default DataTable;