import { Form, Input, Button, InputNumber, Checkbox } from 'antd';
import api from '@src/api';
import { ApiUrl } from '@src/api/api-url';
import { useLocation } from 'react-router-dom';
import {TranslationContent} from 'src/declarations/translation/index';
import TranslationRequestModel from 'src/declarations/translation/RequestModel';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const TranslationEdit = () => {
    let { state }: { state: any } = useLocation();

    const onFinish = (values: any) => {
        const contents = Object.keys(values.jsonContent).map((contentkey) => {
            const content:TranslationContent = { 'key': contentkey, 'content': values.jsonContent[contentkey] };
            return content;
        });

        const requestBody: TranslationRequestModel =
        {
            'isDefault': values.isDefault,
            'locale': values.locale,
            'contents': contents
        };

        const promise = state?.id
            ? api.patch(ApiUrl.translationModify(state.id), requestBody)//Modify 
            : api.post(ApiUrl.translationCreate(), requestBody) //Create

        promise
            .then(() => alert(`${state?.id ? 'Modify' : 'Create'} Success`))
            .catch(() => alert(`${state?.id ? 'Modify' : 'Create'} Fail`));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={state}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
        {/* <Form.Item name="isDefault" label="Default">
            <Checkbox checked ={state.isDefault} />
        </Form.Item> */}
            <Form.Item name="locale" label="Locale">
                <Input />
            </Form.Item>
            <Form.Item name="jsonContent" label="Content">
                <Editor
                    value={state.jsonContent?? {}}
                    onChange={(e: any) => { state.jsonContent = e; }}
                />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4 }}>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </Form.Item>
        </Form >
    );
}

export default TranslationEdit;