import api from "@src/api";
import { ApiUrl } from "@src/api/api-url";
import DataTable from "@src/components/data-table";
import Subscriber from "@src/declarations/subscribe";
import { Input } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";


const SubscriberList = () => {
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState<Subscriber[]>([]);

    useEffect(() => {
        document.title = 'Inclusion Atlas | Subscribe';
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        api.get(ApiUrl.subscribe())
            .then(setData)
            .finally(() => setLoading(false));
    };


    return (
<>
<Input.Search
                placeholder="search records..."
                style={{ marginBottom: 8 }}
                onSearch={(value) => { setSearchText(value) }}
                onChange={(e) => { setSearchText(e.target.value) }}
            />
        <DataTable
            columns={[
                {
                     title: 'Email',
                     dataIndex: 'email',
                     key: 'email',
                     sorter: true,
                     filteredValue: [searchText],
                        onFilter: (value, record) => {
                            return (record as Subscriber).email.toLowerCase().includes((value as string).toLowerCase())       
                        }
                },
                {
                    title: 'Create Date',
                    dataIndex: 'createDate',
                    key: 'createDate',
                    sorter: true,
                    render:(createDate)=> moment(createDate).format('l, h:mm A')
                },
    
                // {
                //     title: 'Action',
                //     key: 'action',
                //     render: (_, record) => (
                //         <Space size="middle">
                //             <Link to="/notification/edit" state={record}>Edit</Link>
                //         </Space>
                //     ),
                // },
            ]}
            dataSource={data}
            loading={loading}
        />
</>
    )


}
export default SubscriberList