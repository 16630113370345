import { useEffect, useState } from 'react';
import { Button, Drawer, Layout, Menu, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import useAppContext from '@src/hooks/use-app-context';
import MainMenu from './main-menu';
import { Link, useNavigate } from 'react-router-dom';
import api from '@src/api';
import { ApiUrl } from '@src/api/api-url';

const { Header } = Layout;
const { Title } = Typography;

const Container = styled(Header)`
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 10px;
  height: auto;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > *{
    margin: 10px;
  }
`;

const DrawerIcon = styled(Button)`
   @media only screen and (min-width: 992px) {
    display: none;
  }
`;

const HeaderTitle = styled(Title)`
   color: white !important;
   margin: 0 !important;
`;

const MainHeader = () => {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const logout = () => {
    dispatch({
      type: 'logout',
      payload: {
        emailAddress: '',
        userId: '',
        accessToken: ''
      }
    });
    navigate('/');
  }
  return (
    <Container>
      <LogoContainer>
        <DrawerIcon
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => setVisible(true)}
        />
        <HeaderTitle level={4}>Inclusion Atlas | Backoffice</HeaderTitle>
      </LogoContainer>

      <HeaderTitle level={5} >
        <Button
          onClick={logout}
          type={"primary"}
          className="form_button"
          color={'#208AEC'}
          size="large"
        >
          Log Out
        </Button>
        {/* {state.emailAddress} */}
      </HeaderTitle>

      <Drawer
        title="Topics"
        placement="left"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <MainMenu />
      </Drawer>
    </Container>
  );
}

export default MainHeader;