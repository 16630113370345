import { useRef } from 'react';
import { Form, Input, Button, Checkbox, Typography, Divider, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ApiUrl } from '@src/api/api-url';
import Captcha from '@src/components/captcha';
import api from '@src/api';
import useAppContext from '@src/hooks/use-app-context';

const { Content } = Layout;

const Main = styled(Layout)`
     display: flex;
     justify-content: center;
     align-items: center; 
     height: 100%;
`;

const Container = styled(Content)`
     display: flex;
     flex-direction: column;
     background-color: white;
     width: 100%;
     max-width: 500px;
     max-height:350px;
     padding: 25px;
     border-radius: 5px;
     box-shadow: 0 0 15px #e3e6eb;
`;

const { Title } = Typography;

const SignInPage = () => {
  const tokenRef = useRef<any>();
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const captcha = await tokenRef.current.getToken();
    api.post(ApiUrl.authenticate(), {
      userName: values.email,
      password: values.password,
      remember: values.remember,
      captcha
    }).then(response => {
      dispatch({
        type: 'login',
        payload: {
          emailAddress: values.email,
          userId: response.id,
          accessToken: response.token
        }
      });
      navigate('/');
    }).catch(error => {
      alert(`${error.data.error.message} ${error.data.error.details}`);
    });
  };


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Main>
      <Container>
        <Title style={{ margin: 0 }}>Sign In</Title>
        <Divider />
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            label='Email'
            name='email'
            rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'The input is not valid E-mail!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Password'
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item name='remember' valuePropName='checked' wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Captcha ref={tokenRef} />

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Container>
    </Main>

  );
}

export default SignInPage;