import { memo, useRef, forwardRef, useImperativeHandle } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import appConfig from '@src/app.json';

interface CaptchaProps {
};

const Captcha = forwardRef((props: CaptchaProps, ref) => {
     const recaptchaRef = useRef<any>();

     useImperativeHandle(ref, () => ({
          async getToken() {
               return await recaptchaRef.current.execute();
          }
     }));

     return (
          <ReCAPTCHA
               sitekey={appConfig.google.reCaptchaKey}
               size="invisible"
               ref={recaptchaRef}
          />
     );
})

export default memo(Captcha);