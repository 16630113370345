
import { Form, Input, Button, Image } from "antd";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import { useLocation } from 'react-router-dom';

const VenueReviewEdit = () => {
    let { state }: { state: any } = useLocation();

    const approve = () => {
        const promise = api.patch(ApiUrl.venueViewsApprove(state.id));

        promise
            .then(() => alert('Approve Success'))
            .catch(() => alert('Approve Fail'));
    };

    const reject = () => {
        const promise = api.patch(ApiUrl.venueViewsReject(state.id));

        promise
            .then(() => alert('Reject Success'))
            .catch(() => alert('Reject Fail'));
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={state}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item name="venueName" label="Venue Name">
                <Input  />
            </Form.Item>
            <Form.Item name="userFullName" label="Username">
                <Input  />
            </Form.Item>
            <Form.Item name="stateString" label="Status">
                <Input  />
            </Form.Item>
            <Form.List name="capabilities">
                {(fields) => (
                    <Form.Item label="Capabilities">
                        {fields.map((field, index) => (
                            <Input style={{ marginBottom: 8 }} value={`${state.capabilities[field.key].name}`} key={index} />
                        ))}
                    </Form.Item>
                )}
            </Form.List>
            <Form.List name="comments">
                {(fields) => (
                    <Form.Item label="Comment">
                        {fields.map((field, index) => (
                            <Input style={{ marginBottom: 8 }} value={`${state.comments[field.key].comment}`} key={index} />
                        ))}
                    </Form.Item>
                )}
            </Form.List>
            <Form.List name="images">
                {(fields) => (
                    <Form.Item label="Images">
                        {fields.map((field, index) => (
                            <Image style={{ marginLeft: 8 }} width={200} src={`${state.images[field.key]}`} key={index} />
                        ))}
                    </Form.Item>
                )}
            </Form.List>
            <Form.Item wrapperCol={{ offset: 4 }}>
                <Button type="primary" htmlType="button" style={{ marginRight: 8 }}
                    onClick={() => { approve(); }}>
                    Approve
                </Button>
                <Button type="primary" danger htmlType="button"
                    onClick={() => { reject(); }}>
                    Reject
                </Button>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4 }}>
            </Form.Item>
        </Form >

    )
}

export default VenueReviewEdit