import appConfig from '@src/app.json';

export class ApiUrl {
     static authenticate = () => `${appConfig.api.baseUrl}/User/Authenticate`;
     static categories = () => `${appConfig.api.baseUrl}/Categories/GetAll`;
     static categoryCreate = () => `${appConfig.api.baseUrl}/Categories/Create`;
     static categoryModify = (id: string) => `${appConfig.api.baseUrl}/Categories/${id}/Modify`;
     static categoryDelete = (id: string) => `${appConfig.api.baseUrl}/Categories/${id}/Remove`;
     static subCategoryDelete = (categoryId: string, subCategoryId:string) => `${appConfig.api.baseUrl}/Categories/${categoryId}/${subCategoryId}/RemoveSubCategory`;
     
     static capabilities = () => `${appConfig.api.baseUrl}/Capabilities/GetAll`;
     static capabilityCreate = () => `${appConfig.api.baseUrl}/Capabilities/Add`;
     static capabilityModify = (id: string) => `${appConfig.api.baseUrl}/Capabilities/${id}/Modify`;
     static capabilityDelete = (id: string) => `${appConfig.api.baseUrl}/Capabilities/${id}/Remove`;

     
     static translations = () => `${appConfig.api.baseUrl}/Translations/GetAll`;
     static translationCreate = () => `${appConfig.api.baseUrl}/Translations/Add`;
     static translationModify = (id: string) => `${appConfig.api.baseUrl}/Translations/${id}/Update`;
     static translationDelete = (id: string) => `${appConfig.api.baseUrl}/Translations/${id}/Remove`;

     static users = () => `${appConfig.api.baseUrl}/User/GetAll`;
     static userDelete = (id: string) => `${appConfig.api.baseUrl}/User/${id}/Remove`;
     static userActive = (id: string) => `${appConfig.api.baseUrl}/User/${id}/Activate`;
     static userDeactive = (id: string) => `${appConfig.api.baseUrl}/User/${id}/Deactivate`;

     static venueViews = () => `${appConfig.api.baseUrl}/VenueViews/GetAll`;
     static venueViewsDelete = (id: string) => `${appConfig.api.baseUrl}/VenueViews/${id}/Remove`;
     static venueViewsReject = (id: string) => `${appConfig.api.baseUrl}/VenueViews/${id}/Reject`;
     static venueViewsApprove = (id: string) => `${appConfig.api.baseUrl}/VenueViews/${id}/Approve`;
     

     static getComments = () => `${appConfig.api.baseUrl}/Comments/GetComments`;
     static deleteComment = (id:string) => `${appConfig.api.baseUrl}/Comments/${id}/DeleteComment`;
     static updateComment = () => `${appConfig.api.baseUrl}/Comments/UpdateComment`;
     static addComment = () => `${appConfig.api.baseUrl}/Comments/AddComment`;
     static addCategory = () => `${appConfig.api.baseUrl}/Comments/AddCategory`;
     static updateCategory = () => `${appConfig.api.baseUrl}/Comments/UpdateCategory`;
     static deleteCategory = (id: string) => `${appConfig.api.baseUrl}/Comments/${id}/DeleteCategory`;


     static venueOwner = () => `${appConfig.api.baseUrl}/VenueOwners/GetAll`;
     static venueOwnerApprove = (id: string) => `${appConfig.api.baseUrl}/VenueOwners/${id}/Approve`;
     static venueOwnerReject = (id: string) => `${appConfig.api.baseUrl}/VenueOwners/${id}/Reject`;
     static venueOwnerDelete = (id: string) => `${appConfig.api.baseUrl}/VenueOwners/${id}/Remove`;
     static venueOwnerUpdate = () => `${appConfig.api.baseUrl}/VenueOwners/Update`;
     static userVenues = () => `${appConfig.api.baseUrl}/VenueOwners/UserVenues`;

     static notifications = () => `${appConfig.api.baseUrl}/Notifications/GetAllEmailTemplates`;
     static addEmailTemplate = () => `${appConfig.api.baseUrl}/Notifications/AddEmailTemplate`;
     static updateEmailTemplate = () => `${appConfig.api.baseUrl}/Notifications/Update`;
     static getEmailTemplate = (id:string) => `${appConfig.api.baseUrl}/Notifications/${id}/GetEmailTemplate`;

     static messages = () => `${appConfig.api.baseUrl}/VenueMessage/GetAll`;
     static addFromAdmin = () => `${appConfig.api.baseUrl}/VenueMessage/AddFromAdmin`;
     static deleteMessage = (id:string) => `${appConfig.api.baseUrl}/VenueMessage/${id}/Delete`;

     static contactUs = () => `${appConfig.api.baseUrl}/ContactUs/GetAll`;
     static subscribe = () => `${appConfig.api.baseUrl}/Subscriber/GetAll`;


}
