import { Form, Input, Button, InputNumber, Upload, Image, Space } from 'antd';
import api from '@src/api';
import { ApiUrl } from '@src/api/api-url';
import { useLocation } from 'react-router-dom';
import CapabilityRequestModel from 'src/declarations/capability/RequestModel'

const CapabilityEdit = () => {
    const { state }: { state: any } = useLocation();

    const onFinish = (values: any) => {

        const requestBody: CapabilityRequestModel =
        {
            'order': values.order,
            'name': values.name
        };

        const promise = state?.id
            ? api.patch(ApiUrl.capabilityModify(state.id), requestBody)//Modify 
            : api.post(ApiUrl.capabilityCreate(), requestBody) //Create

        promise
            .then(() => alert(`${state?.id ? 'Modify' : 'Create'} Success`))
            .catch(() => alert(`${state?.id ? 'Modify' : 'Create'} Fail`));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={state}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item name="order" label="Order">
                <InputNumber />
            </Form.Item>
            <Form.Item name="name" label="Name">
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4 }}>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </Form.Item>
        </Form >
    );
}

export default CapabilityEdit;