import { useEffect, useState } from "react";
import { Image, Popconfirm, Space, Table } from "antd";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import { Link } from "react-router-dom";
import DataTable from "@src/components/data-table";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Capability from "src/declarations/capability/index"

const CapabilityList = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Capability[]>([]);

    useEffect(() => {
          document.title = 'Inclusion Atlas | Capabalities';
         loadData();
    }, []);

    const loadData = () => {
         setLoading(true);
         api.get(ApiUrl.capabilities())
              .then(setData)
              .finally(() => setLoading(false));
    };

    const remove = (record: Capability) => {
         setLoading(true);
         api.delete(ApiUrl.capabilityDelete(record.id))
              .then(loadData)
              .catch(() => setLoading(false));
    }

    return (
         <DataTable
              columns={[
                   {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: true,
                   },
                   {
                        title: 'Order',
                        dataIndex: 'order',
                        key: 'order',
                        sorter: true,
                   },
                   {
                        title: 'Action',
                        key: 'action',
                        render: (_, record) => (
                             <Space size="middle">
                                  <Link to="/capability/edit" state={record}>Edit</Link>
                                  <Popconfirm
                                       title="Are you sure to delete ?"
                                       onConfirm={() => remove(record as Capability)}
                                       okText="Yes"
                                       cancelText="No"
                                       icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  >
                                       <a href="#">Delete</a>
                                  </Popconfirm>
                             </Space>
                        ),
                   },
              ]}
              dataSource={data}
              loading={loading}
         />
    );
}

export default CapabilityList;