import { BrowserRouter, Routes, Route, Navigate, useLocation, RouteProps } from 'react-router-dom';
import "antd/dist/antd.less";
import "@src/assets/styles/main.less";
import { AppContextProvider } from '@src/context';
import 'jsoneditor-react/es/editor.min.css';
import HomePage from "@src/layout-pages/home";
import SignInPage from '@src/layout-pages/auth/sign-in';


const App = () => {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/*" element={<HomePage />}></Route>
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
