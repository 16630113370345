export enum SortOrder{
     Ascend  = 1,
     Descend = 2,
}

interface Sort{
     field?: string,
     order?: SortOrder
}

export default Sort;