import { Breadcrumb } from 'antd';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const WrappedBreadcrumb = styled(Breadcrumb)`
     margin: 16px 0;
`;

const MainAddressBar = () => {
     const { pathname } = useLocation();

     const pascalCase = (title: string) => title.replace(
          /\w\S*/g,
          t => t.charAt(0).toUpperCase() + t.substring(1).toLowerCase()
     );

     return (
          <WrappedBreadcrumb>
               {
                    pathname.split('/').map(pathPart =>
                         <Breadcrumb.Item key={pathPart}>
                              {pascalCase(pathPart)}
                         </Breadcrumb.Item>
                    )
               }
          </WrappedBreadcrumb>
     );
}

export default MainAddressBar;