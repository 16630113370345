import lscache from 'lscache';
import { Context } from '@src/context';
type Method = 'GET' | 'DELETE' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH';

class Api {
     private getAuthenticationToken() {
          const auth = lscache.get('auth');
          return auth?.accessToken;
     }

     private async getResult(response: Response) {
          try {
               let json = await response.text();
               let object = JSON.parse(json);
               return object;
          }
          catch {
               return null;
          }
     }

     private ajax<T = any>(url: string, method: Method, data?: any, headers?: Headers): Promise<T> {
          let promise = Promise.race([
               new Promise<any>((resolve, reject) => {
                    var myHeaders = headers || new Headers();

                    if (!(data instanceof FormData)) {
                         myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
                    }

                    myHeaders.append('Accept-Language', 'en');

                    const accessToken = this.getAuthenticationToken();
                    if (accessToken) {
                         myHeaders.append('Authorization', `Bearer ${accessToken}`);
                    }

                    let params: any = {
                         method: method,
                         headers: myHeaders
                    };

                    if (data) {
                         params.body = data instanceof FormData ? data : JSON.stringify(data);
                    }

                    fetch(url, params)
                         .then(async response => {
                              if (response.status >= 200 && response.status <= 299) {
                                   const jsonResponse = await this.getResult(response);
                                   return resolve(jsonResponse?.data);
                              }
                              else {
                                   const content = await this.getResult(response);
                                   reject({ statusCode: response.status, error: content?.error });
                                   switch (response.status) {
                                        case 401:
                                             Context.dispatch({
                                                  type: 'logout'
                                             })
                                             return;
                                        case 500:
                                        //Server Error
                                        default:
                                             console.log(response);
                                   }

                              }
                         })
                         .catch(() => reject())
               }),
               new Promise((_, reject) => setTimeout(() => {
                    reject({ status: 0, data: 'Connection timeout' });
               }, 3000000))
          ]);
          return promise;
     }

     get<T = any>(url: string, headers?: Headers): Promise<T> {
          return this.ajax<T>(url, 'GET');
     }

     post<T = any>(url: string, data?: any, headers?: Headers): Promise<T> {
          return this.ajax<T>(url, 'POST', data);
     }

     patch<T = any>(url: string, data?: any, headers?: Headers): Promise<T> {
          return this.ajax<T>(url, 'PATCH', data);
     }
     put<T = any>(url: string, data?: any, headers?: Headers): Promise<T> {
          return this.ajax<T>(url, 'PUT', data);
     }
     delete<T = any>(url: string, headers?: Headers): Promise<T> {
          return this.ajax<T>(url, 'DELETE');
     }
}

const api = new Api();
export default api;