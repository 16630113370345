import { Upload, Button } from "antd";
import { useEffect, useState } from "react";

interface UploadFileProps {
  value?: string;
  onChange?: (value: File) => void;
}

const UploadFile: React.FC<UploadFileProps> = ({ value, onChange }) => {
  const [fileList, setFileList] = useState<any[]>([]);

  useEffect(() => {
    if (onChange) {
      onChange(fileList[0]?.originFileObj)
    }
  }, [fileList]);

  const dummyRequest = (options: any) => {
    setTimeout(() => {
      options.onSuccess("ok");
    }, 0);
  };

  const triggerChange = (info: any) => {
    switch (info.file.status) {
      case "uploading":
        setFileList([info.file]);
        break;
      case "done":
        setFileList([info.file]);
        break;
      default:
        setFileList([]);
    }
  };

  return (
    <Upload
      fileList={fileList}
      customRequest={dummyRequest}
      onChange={triggerChange}
    >
      <Button>Choose File</Button>
    </Upload>
  );
}

export default UploadFile;