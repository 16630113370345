import api from "@src/api";
import { ApiUrl } from "@src/api/api-url";
import DataTable from "@src/components/data-table";
import { Button, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import notifications from "src/declarations/notifications/index";


const EmailTemplateList = () => {
     const [loading, setLoading] = useState(false);
     const [data, setData] = useState<notifications[]>([]);

     useEffect(() => {
          document.title = 'Inclusion Atlas | Email Templates';
          loadData();
     }, []);

     const loadData = () => {
          setLoading(true);
          api.get(ApiUrl.notifications())
               .then(data => {
                    setData(data)
               })
               .finally(() => setLoading(false));
     };

     return (
          <>
               <Link to="/notification/edit" state={{ body: {} }}>
                    <Button type='primary'>
                         New Template
                    </Button>
               </Link>
               <DataTable
                    columns={[
                         {
                              title: 'Type Id',
                              dataIndex: 'type',
                              key: 'type',
                              sorter: true,
                              width:100,
                         },
                         {
                              title: 'Type Title',
                              dataIndex: 'typeTitle',
                              key: 'typeTitle',
                              sorter: true,
                         },
                         {
                              title: 'Subject',
                              dataIndex: 'subject',
                              key: 'subject',
                              sorter: true,
                         },
                         {
                              title: 'Create Date',
                              dataIndex: 'createDate',
                              key: 'createDate',
                              sorter: true,
                              render: (createDate) => moment(createDate).format('l, h:mm A')
                         },

                         {
                              title: 'Action',
                              key: 'action',
                              render: (_, record) => (
                                   <Space size="middle">
                                        <Link to="/notification/edit" state={record}>Edit</Link>
                                   </Space>
                              ),
                         },
                    ]}
                    dataSource={data}
                    loading={loading}
               />
          </>
     )


}
export default EmailTemplateList