import { useEffect, useState } from "react";
import { Space, Image, Input } from "antd";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import { Link } from "react-router-dom";
import DataTable from "@src/components/data-table";
import VenueOwner from "src/declarations/venue-owner";
import { useLocation } from 'react-router-dom';
import moment from "moment";

const VenueOwnerList = () => {
    let { state }: { state: any } = useLocation();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<VenueOwner[]>([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        document.title = 'Inclusion Atlas | Venue Owners List';
        loadData();
    }, [])

    const loadData = () => {
        setLoading(true);
        api.get(ApiUrl.venueOwner())
            .then(setData)
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Input.Search
                placeholder="search records..."
                style={{ marginBottom: 8 }}
                onSearch={(value) => { setSearchText(value) }}
                onChange={(e) => { setSearchText(e.target.value) }}
            />
            <DataTable
                columns={[
                    {
                        title: 'Owner FullName',
                        dataIndex: 'ownerFullName',
                        key: 'ownerFullName',
                        sorter: true,
                        width: 150,
                        // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                        //     return (
                        //         <div className="custom-filter-dropdown" style={{ width: 198, padding: 4,alignItems:'center' }}>
                        //             <Input
                        //                 autoFocus
                        //                 style={{ width: 190, marginBottom: 8, display: "block" }}
                        //                 placeholder="search venue owner"
                        //                 value={selectedKeys[0]}
                        //                 onChange={(e) => {
                        //                     setSelectedKeys(e.target.value ? [e.target.value] : [])
                        //                     confirm({closeDropdown:false});
                        //                 }}
                        //                 onPressEnter={() => { confirm() }}
                        //                 onBlur={() => { confirm() }}
                        //             />
                        //             <Button onClick={() => confirm()} type="primary" size="small"style={{ width: 90, margin:2, alignItems:'center' }}>Search</Button>
                        //             <Button 
                        //              onClick={() => clearFilters?.()} 
                        //             type="primary" size="small" style={{ width: 90 ,margin:2}} >Reset</Button>
                        //         </div>)
                        // },
                        // filterIcon: () => {
                        //     return <SearchOutlined />
                        // },
                        // onFilter: (value, record) => {
                        //     return (record as VenueOwner).ownerFullName.toLowerCase().includes((value as string).toLowerCase())
                        // },
                        filteredValue: [searchText],
                        onFilter: (value, record) => {
                            return (record as VenueOwner).ownerFullName.toLowerCase().includes((value as string).toLowerCase()) ||
                                (record as VenueOwner).name.toLowerCase().includes((value as string).toLowerCase()) ||
                                (record as VenueOwner).stateString.toLowerCase().includes((value as string).toLowerCase());;
                        }
                    },
                    {
                        title: 'Show All Venues ',
                        dataIndex: 'ownerId',
                        key: 'ownerId',
                        sorter: true,
                        width: 150,
                        render: (text) => <Link to={`/venueOwner/getAll?ownerId=${text}`}>{'All Venues'}</Link>,
                   },
                    {
                        title: 'Venue Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: true
                    },
                    {
                        title: 'Business Legal Name',
                        dataIndex: 'businessLegalName',
                        key: 'businessLegalName',
                        sorter: false,
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        sorter: false,
                    },
                    {
                        title: 'State',
                        dataIndex: 'stateString',
                        key: 'stateString',
                        sorter: false,
                        width: 100,
                    },
                    {
                        title: 'Submission Date',
                        dataIndex: 'creationDate',
                        key: 'creationDate',
                        sorter: true,
                        render: (creationDate) => moment(creationDate).format('l, h:m A')
                    },
                    {
                        title: 'Rejection Date',
                        dataIndex: 'rejectionDate',
                        key: 'rejectionDate',
                        sorter: true,    
                    },
                    {
                        title: 'Approval Date',
                        dataIndex: 'approvalDate',
                        key: 'approvalDate',
                        sorter: true,
                        // render: (approvalDate) => moment(approvalDate).format('l, h:m A')
                    },
                    {
                        title: 'Action',
                        key: 'action',
                        render: (_, record) => (
                            <Space size="middle">
                                <Link to="/venueOwner/edit" state={record}>Details</Link>
                            </Space>
                        ),
                    },
                ]}
                dataSource={data}
                loading={loading}
            /></>
    )
}
export default VenueOwnerList