import { Layout } from 'antd';
import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useAppContext from '@src/hooks/use-app-context';
import MainAddressBar from './main-address-bar';
import MainContent from './main-content';
import MainHeader from './main-header';
import MainSidebar from './main-sidebar';
import CategoryList from '@src/pages/category/list';
import CategoryEdit from '@src/pages/category/edit';
import CapabilityList from '@src/pages/capability/list';
import CapabilityEdit from '@src/pages/capability/edit';
import TranslationList from '@src/pages/translation/list';
import TranslationEdit from '@src/pages/translation/edit';
import UserList from '@src/pages/users/list';
import VenueOwnerList from '@src/pages/venue-owner/list';
import VenueOwnerEdit from '@src/pages/venue-owner/edit';
import EmailTemplateList from '@src/pages/notification/list';
import EmailTemplateEdit from '@src/pages/notification/edit';
import MessagesList from '@src/pages/venue-message/list';
import ContactUsListMessages from '@src/pages/contact-us/list';
import SubscriberList from '@src/pages/subscribe/list';
import VenueReviewsList from '@src/pages/venue-review/list';
import VenueReviewEdit from '@src/pages/venue-review/edit';
import GetAllUserReviews from '@src/pages/venue-review/getAll';
import GetAllUserVenues from '@src/pages/venue-owner/getAll';
import CommentsList from '@src/pages/reviews/list';
import CategoryCommentEdit from '@src/pages/reviews/edit';




const Main = styled(Layout)`
     height: 100%;
`;

const Container = styled(Layout)`
    padding: 0 24px 24px;
    margin-left: 4rem;

    @media only screen and (max-width: 992px) {
     margin-left: 0rem;
   }
     
`;

const HomePage = () => {
     const { state } = useAppContext();
     const navigate = useNavigate();

     useEffect(() => {
          if (!state.accessToken) {
               navigate('sign-in');
          }
     }, [state.accessToken])

     return (
          <Main>
               <MainHeader />
               <Layout>
                    <MainSidebar />
                    <Container>
                         <MainAddressBar />
                         <MainContent>
                              <Routes>
                                   <Route path="" element={<div style={{color:'InfoText', fontWeight:'bold',fontSize:'large'}}>Welcome {state.emailAddress}</div>} />
                                   <Route path="category/list" element={<CategoryList />} />
                                   <Route path="category/edit" element={<CategoryEdit />} />
                                   <Route path="capability/list" element={<CapabilityList />} />
                                   <Route path="capability/edit" element={<CapabilityEdit />} />
                                   <Route path="categoryComment/list" element={<CommentsList />} />
                                   <Route path="categoryComment/edit" element={<CategoryCommentEdit />} />
                                   <Route path="translation" element={<TranslationList />} />
                                   <Route path="translation/edit" element={<TranslationEdit />} />
                                   <Route path="user/list" element={<UserList />} />
                                   <Route path="venueReview/list" element={<VenueReviewsList />} />
                                   <Route path="venueReview/edit" element={<VenueReviewEdit />} />
                                   <Route path="venueReview/getAll" element={<GetAllUserReviews />} />
                                   <Route path="venueOwner/list" element={<VenueOwnerList />} />
                                   <Route path="venueOwner/edit" element={<VenueOwnerEdit />} />
                                   <Route path="venueOwner/getAll" element={<GetAllUserVenues />} />
                                   <Route path="notification/list" element={<EmailTemplateList />} />
                                   <Route path="notification/edit" element={<EmailTemplateEdit />} />
                                   <Route path="message/list" element={<MessagesList />} />
                                   <Route path="contactUs/list" element={<ContactUsListMessages />} />
                                   <Route path="subscriber/list" element={<SubscriberList/>} />
                              </Routes>
                         </MainContent>
                    </Container>
               </Layout>
          </Main>
     );
}

export default HomePage;
