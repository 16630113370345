import lscache from 'lscache';
import AppState from "@src/declarations/context/app-state";
import AppAction from "@src/declarations/context/app-action";

const appReducer = (state: AppState, action: AppAction) => { 
     switch (action.type) {
          case 'login': {
               const { emailAddress, userId, accessToken } = action.payload;
               const newState = { ...state, emailAddress, userId, accessToken };
               lscache.set('auth', newState);
               return newState;
          }
          case 'logout': {
               const newState = { ...state, emailAddress: '', userId: '', accessToken: '' };
               lscache.set('auth', newState);
               return newState;
          }
          default: {
               throw new Error(`Unhandled action type: ${action.type}`)
          }
     }
}

export default appReducer;
