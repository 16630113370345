import { useEffect, useState } from "react";
import { Input, Popconfirm, Space } from "antd";
import api from '@src/api';
import { ApiUrl } from "@src/api/api-url";
import { Link } from "react-router-dom";
import DataTable from "@src/components/data-table";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useLocation } from 'react-router-dom';
import VenueReview from "@src/declarations/venue-review";
import moment from 'moment';
import useQuery from "@src/hooks/use-query";


const GetAllUserReviews = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<VenueReview[]>([]);
    const query = useQuery();
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const viewer_id = query.get('viewerId');
        if (!viewer_id) {
             return;
        }
        loadData(viewer_id);
    }, []);

    const loadData = (viewerId: String) => {
        setLoading(true);
       
        api.get(ApiUrl.venueViews() + `?viewerId=${viewerId}`)
            .then(setData)
            .finally(() => setLoading(false));
    };

    const remove = (record: VenueReview) => {
        setLoading(true);
        api.delete(ApiUrl.venueViewsDelete(record.id))
            .then(loadData)
            .catch(() => setLoading(false));
    }
    return (
        <>
            <Input.Search
                placeholder="search records..."
                style={{ marginBottom: 8 }}
                onSearch={(value) => { setSearchText(value) }}
                onChange={(e) => { setSearchText(e.target.value) }}
            />
            <DataTable
                columns={[
                    {
                        title: 'User Fullname ',
                        dataIndex: 'userFullName',
                        key: 'userFullName',
                        sorter: true,
                        width: 150,
                        // filteredValue: [searchText],
                        // onFilter: (value, record) => {
                        //     return (record as VenueReview).userFullName.toLowerCase().includes((value as string).toLowerCase()) ||
                        //         (record as VenueReview).venueName.toLowerCase().includes((value as string).toLowerCase()) ||
                        //         (record as VenueReview).stateString.toLowerCase().includes((value as string).toLowerCase());;
                        // }
                    },
                    {
                        title: 'Venue Name ',
                        dataIndex: 'venueName',
                        key: 'venueName',
                        sorter: true,
                        width: 150,
                        filteredValue: [searchText],
                        onFilter: (value, record) => {
                            return (record as VenueReview).venueName.toLowerCase().includes((value as string).toLowerCase()) ||
                                (record as VenueReview).stateString.toLowerCase().includes((value as string).toLowerCase());;
                        }
                    },
                    // {
                    //      title: 'Comments',
                    //      dataIndex:'comments',
                    //      key:'comments',
                    //      width: 200,
                    //      render:(comments, index) => comments.map((item: { comment: string; }, index:string) => item.comment ).join(', ')                                                                        
                    // },

                    // {
                    //      title: 'Category Title',
                    //      dataIndex:'comments',
                    //      key:'comments',
                    //      render:(comments) => comments.map((item: { categoryTitle: string; }) => item.categoryTitle).join()                                                                        
                    // },
                    // {
                    //      title: 'Images',
                    //      dataIndex:'images',
                    //      key:'images',
                    //      width: 100,
                    //      render:(images) => images.map((image:string , index:number) => <Image src={image} height={50} key={index}/>)                                                                
                    // },
                    {
                        title: 'star',
                        dataIndex: 'star',
                        key: 'star',
                        sorter: true,
                        width: 70,
                    },
                    {
                        title: 'Creation Time',
                        dataIndex: 'creationTime',
                        key: 'creationTime',
                        sorter: true,
                        width: 100,
                        render: (creationTime) => moment(creationTime).format('l, h:mm A')
                    },
                    // {
                    //      title: 'Capabilities',
                    //      dataIndex:'capabilities',
                    //      key:'capabilities',
                    //      render: (capabilities) => capabilities.map((item: { name: string; }) =>item.name).join(', ')
                    // },
                    {
                        title: 'Status',
                        dataIndex: 'stateString',
                        key: 'stateString',
                        sorter: true,
                        width: 100,
                    },
                    {
                        title: 'Action',
                        key: 'action',
                        width: 100,
                        render: (_, record) => (
                            <Space size="middle">
                                <Link to="/venueReview/edit" state={record}>Edit</Link>
                                <Popconfirm
                                    title="Are you sure to delete?"
                                    onConfirm={() => remove(record as VenueReview)}
                                    okText="Yes"
                                    cancelText="No"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                >
                                    <a href="#">Delete</a>
                                </Popconfirm>
                            </Space>
                        ),
                    },

                ]}
                dataSource={data}
                loading={loading}
            />
        </>
    );
}

export default GetAllUserReviews;

